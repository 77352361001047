:root {
  --header-background-color: #515699;
  --main-background-color: #E68960;
  --third-color: #4954E6;
  --fourth-color: #6CE632;
  --fifth-color: #4D9929;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header h1{
  margin: 0px;
}

.todo-header {
  /* background-color: #282c34; */
  background-color: var(--header-background-color);
  color: white;
  box-shadow: 5px 5px 5px black;
}

.todo-header h1 {
  padding: 2% 0px;
  
}

.todo-header nav a{
  text-decoration: none;
  padding: 0px 10px;
  color: white;
}

.mainBody {
  margin: 3% 20%;
  padding: 5%;
  border-radius: 15px;
  box-shadow: 5px 5px 5px black;
  /* background-color: rgb(165, 190, 245); */
  background-color: var(--main-background-color);
}

form {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

input {
  margin: 0px 3%;
  height: 25px;
  border-radius: 5px;
  border-width: 1px;
  /* font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
}

form label{
  font-weight: bold;
  font-size: 1.3rem;
}

button {
  height: 30px;
  background-color: var(--third-color);
  color: white;
  border-color: transparent;
  border-radius: 15px;
  box-shadow: 1px 1px 1px black;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;  
}

button:hover {
  background-color: var(--fifth-color);
}

/* ul {
  display: grid;
  justify-content: column;

} */

/* .todoListItemWithButton{
  display: grid;
  grid-template-columns: 4fr 1fr;
} */

.todoListItemWithButton button {
  width: 60px;
  height: 20px;
  margin: 0px 0px 0px 10px;
}



li {
  list-style-type: none;
  text-align: left;
  margin: 0px 0px 15px 15%;
  cursor: pointer;
}

li .liItem {
  margin: 0px 0px 0px 25px;
}

.increment{
  margin: 0px 0px 0px 15px;
  width: 40px;

}

/* li:before { 
    content: '\1f532';
    margin-left: -20px; 
    margin-right: 10px; 
} */

.increment span {
  /* border: 1px solid black; */
  width: 35px;
  height: 15px;
  margin-left: 5px;
}


.complete {
  color: green;
  text-decoration: line-through;
}